import styled from "styled-components";
import { space, color, layout, typography, border, flexbox } from "styled-system";
import propTypes from "@styled-system/prop-types";

const FakeLink = styled.a`
  margin: 0;
  padding: 0;
  line-height: 1.3;

  opacity: .5;
  
  cursor: default;
  pointer-events: none;
  text-decoration: underline;
  
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${flexbox}
  
  &:hover {
    text-decoration: none;
  }
`;

FakeLink.displayName = `FakeLink`;

FakeLink.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.border,
  ...propTypes.flexbox,
};

export { FakeLink };
