import React, { useContext } from "react";
import { CONTRACT_SOURCE_ID_NEWS, PAGE_HP, PAGE_OMNIS } from "../../constants";
import { Helmet } from "react-helmet";
import config from "../../configs";
import { Box, Column, Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { StaticImage } from "gatsby-plugin-image";

import { OmnisLPBanner } from "../Banners/OmnisLPBanner";
import WhyMND from "../whyMND";
import { FAQItem } from "../FAQ/FAQItem";
import LayoutMain from "../layoutMain";
import OmnisSituationsList from "../OmnisSituation/OmnisSituationsList";
import loadable from "@loadable/component";
import { TabsNavForm } from "../../style/HPForm";
import { FakeLink } from "../../style/Link/FakeLink";
import Loading from "../loading";
import ContactForm from "../contactForm";
import { getCommodityFromHash } from "../../helpers";
import Maintenance from "../Maintenance";
import { infoContext } from "../../context/infoProvider";
import { OrderedList } from "../../style/OrderedList";

const FormContainer = loadable(() => import("../../components/formContainer"), {
  fallback: (
    <Wrapper backgroundColor="grey.lighter" zIndex={50}>
      <Box position="relative">
        <Container mt={[3, -55]} mb={5} width={"100%"} position="relative">
          <TabsNavForm>
            <FakeLink to={"#"} activeClassName="active">
              <div>
                <img src={require("../../images/HP_dark.gif").default} alt="" />
                <img src={require("../../images/HP_light.gif").default} alt="" />
              </div>
              Plyn
            </FakeLink>
            <FakeLink to={"#"} activeClassName="active">
              <div>
                <img src={require("../../images/HP_el_dark.gif").default} alt="" />
                <img src={require("../../images/HP_el_light.gif").default} alt="" />
              </div>
              Elektřina
            </FakeLink>
          </TabsNavForm>
          <Box width={1} backgroundColor="white.100">
            <Loading minHeight={[730, 400]} />
          </Box>
        </Container>
      </Box>
    </Wrapper>
  ),
});

const OmnisChangeLP = ({ contentData, articles }) => {
  const { maintenance } = useContext(infoContext);

  return (
    <LayoutMain type={PAGE_HP}>
      <Helmet htmlAttributes={{ lang: "cs" }}>
        <title>{contentData.title}</title>
        <meta name="description" content={contentData.description} />
        <meta property="og:image" content={`${config.serverUrl}${contentData.ogImage}`} />
        <script>{`
          var gaAdditionalPageData = JSON.stringify({ type: 'Category', section: 'Gas | Electricity', categories: '${contentData.analyticsCategories}' });
        `}</script>
      </Helmet>

      <OmnisLPBanner {...contentData.banner} />

      <Wrapper backgroundColor={["none", null, "exploratoryGreen"]}>
        <Container flexDirection={"row"} alignItems={"stretch"} flexWrap={["wrap", null, "nowrap"]} justifyContent={"center"}>
          <Column
            flex={["1 1 100%", null, "0 0 51%"]}
            maxWidth={["none", null, "51%"]}
            pt={[40, null, 80]}
            pb={[40, null, 100]}
            pr={64}
            backgroundColor={"exploratoryGreen"}
          >
            <Heading
              as="h3"
              fontWeight="700"
              fontSize={[5, 6]}
              color="white.100"
              mb={["26px", null, "48px"]}
              dangerouslySetInnerHTML={{
                __html: contentData.stepsToMND.title,
              }}
            />
            <OrderedList>
              {contentData.stepsToMND.steps.map((step, index) => (
                <li
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: step,
                  }}
                />
              ))}
            </OrderedList>
          </Column>
          <Column
            as={Flex}
            flexDirection={"column"}
            flex={["1 1 100%", null, "0 0 49%"]}
            maxWidth={["none", null, "559px"]}
            px={24}
            pt={[0, null, 42]}
            pb={[10, null, 42]}
            justifyContent={"center"}
            backgroundColor={"resourceGreenLighter"}
            id={"ContactForm"}
            position={"relative"}
          >
            <Maintenance />
            <ContactForm
              leadsource={contentData.leadId}
              enableCallbackTime={false}
              enableEmail={false}
              messageOk={contentData.leadSuccessMessage}
              commodity={getCommodityFromHash(typeof document !== "undefined" ? document.location.hash : "")}
              analytics={{
                name: contentData.analyticsCategories,
                text: "Zavolejte mi",
                type: "Lead",
                section: "Product",
                sourceId: contentData.leadId,
              }}
            />
          </Column>
        </Container>
      </Wrapper>

      <Wrapper backgroundColor="lightGrey">
        <Container>
          <Flex>
            <Flex flexDirection="column" width="100%">
              <Flex flexDirection="column" alignItems={["flex-start", null, "center", null]}>
                <StaticImage src="../../images/paticka_miner.jpg" alt="mnd" loading="lazy" quality={80} />
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <WhyMND pt={5} showArticle={false} />
      <Wrapper>
        <Container
          flexDirection={"column"}
          alignItems={["center", null, "stretch"]}
          justifyContent={"space-between"}
          maxWidth={785}
          pt={[40, null, 64]}
          pb={[45, null, 72]}
        >
          <Heading
            as="h3"
            fontSize={[5, null, 6]}
            fontWeight="700"
            color={"exploratoryGreen"}
            mb={["20px", null, "40px"]}
            dangerouslySetInnerHTML={{ __html: contentData.FAQSection.title }}
          />
          <Flex flexDirection={"column"}>
            {contentData.FAQSection.FAQs.map(faq => (
              <FAQItem question={faq.question} answer={faq.answer} />
            ))}
          </Flex>
          {/* #23325016 Zakomentování části Přečtěte si více pro stránku /prepis-energii/zmena-dodavatele */}
          {/* {articles.length ? (
            <>
              <Heading as="h2" fontSize={[28, null, 42]} lineHeight={1.1} fontWeight="700" color={"exploratoryGreen"} mt={[48, null, 96]} mb={[32, null, 48]}>
                Přečtěte si více
              </Heading>

              <MoreNews articles={articles} loadMore={false} showDate={false} style={PAGE_OMNIS} />
            </>
          ) : null} */}
        </Container>
      </Wrapper>
      <OmnisSituationsList
        active={`/prepis-energii/${contentData.slug}`}
        title={"Řešíte jinou situaci?"}
        formScrollToId={contentData.formScrollToId}
        showScrollToContact={false}
      />

      {/*{maintenance?.maintenance_in_progress && (*/}
      {/*  <Wrapper*/}
      {/*    background={["white.100", `#a7be32 url(${require("../../images/contactForm__bg.jpg").default}) no-repeat bottom center`]}*/}
      {/*    p={["30px 0", "60px 0 40px"]}*/}
      {/*  >*/}
      {/*    <Container id="contactForm" position="relative">*/}
      {/*      <Maintenance />*/}
      {/*      <Flex flexDirection="column" alignItems={["flex-start", "center"]} width={1} mb={[0, 50]}>*/}
      {/*        <Heading as="h2" fontSize={["26px", null, "36px", null]} fontWeight="700" color={["green.dark", "white.100"]}>*/}
      {/*          Získejte výhodné energie od MND*/}
      {/*        </Heading>*/}
      {/*      </Flex>*/}
      {/*      <ContactForm*/}
      {/*        leadsource={contentData.leadId}*/}
      {/*        enableCallbackTime={false}*/}
      {/*        enableEmail={false}*/}
      {/*        type="PAGE_HP"*/}
      {/*        commodity={getCommodityFromHash(typeof document !== "undefined" ? document.location.hash : "")}*/}
      {/*      />*/}
      {/*    </Container>*/}
      {/*  </Wrapper>*/}
      {/*)}*/}
    </LayoutMain>
  );
};

export default OmnisChangeLP;
