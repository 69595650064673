import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import { Box } from "../Grid";

const TabsNavForm = styled(Box)`
  
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  a {
    position: relative;
    width: 100%;
    display: inline-flex;
    max-width: 200px;
    height: 55px;
    line-height: 55px;
    padding: 0;
    justify-content: center;
    font-size: 17px;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    background-color: rgba(255, 255, 255, 0.85);
    color: ${themeGet("colors.green.dark")};
    @media screen and (min-width: ${props => props.theme.breakpoints.xs}) {
      font-size: 22px;
    }
    div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 12px;
      width: 22px;
      img {
        display: block;
        position: absolute;
        top: 40%;
        left: 0;
        margin: 0;
        width: 18px;
        transform: translateY(-50%);
        &:first-of-type {
          display: block;
        }
        &:last-of-type {
          display: none;
        }
      }
    }
    &:first-of-type {
      margin-right: 3px;
      padding-left: 20px;
    }
    &:last-of-type {
      padding-left: 20px;
    }
    &.active {
      color: ${themeGet("colors.green.light")};
      background-color: ${themeGet("colors.white.100")};
      div {
        img {
          &:first-of-type {
            display: none;
          }
          &:last-of-type {
            display: block;
          }
        }
      }
    }
  }
`;

TabsNavForm.displayName = "TabsNavForm";

TabsNavForm.defaultProps = {
    width: ["100%", null, null, null, "containerMaxWidth"],
    margin: "auto"
};

export { TabsNavForm };
