import React from "react";

import { OMNIS_ZMENA_DODAVATELE_SLUG } from "../../constants";
import OmnisChangeLP from "../../components/OmnisChangeLP";
import { OmnisData } from "../../content/OmnisData";
import { graphql, useStaticQuery } from "gatsby";

const Zmena = () => {
  // TODO: doplnit/zkontrolovat
  const articles = useStaticQuery(graphql`
    query ZmenaQuery {
      allNews(filter: { newsId: { in: [100, 105] } }, limit: 2) {
        edges {
          node {
            id
            name
            newsId
            perex
            slug
            descriptionImage
            releaseDate {
              date
            }
          }
        }
      }
    }
  `);

  return <OmnisChangeLP contentData={OmnisData[OMNIS_ZMENA_DODAVATELE_SLUG]} articles={articles.allNews.edges} />;
};

export default Zmena;
